import styled, { css } from "styled-components";

export const Container = styled.div`
  min-height: 600px;
  padding: 3vh 5vh;

  @media (max-width: 500px) {
    padding: 1vh 2vh;
  }
`;

export const Header = styled.div`
  padding-bottom: 3vh;

  @media (max-width: 500px) {
    padding-bottom: 1vh;
  }
`;
