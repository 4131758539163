const COLORS = {
  black: '#000000',
  gray: '#c1c4cb',
  blue: '#3a66fb',
  white: '#FFFFFF',
}

export const questionsData = [
  {
    title: "Posso compartilhar a assinatura com outros colegas?",
    content: 'A assinatura é individual e não pode ser compartilhada nem transferida para outra titularidade. Nosso software detecta e libera a utilização do site por meio do IP. A utilização por mais de uma pessoa, via compartilhamento de login será prontamente detectada e medidas legais cabíveis podem ser aplicadas.',
    id: 2,
    color: COLORS.blue,
    textColor: COLORS.white
  },
  {
    title:
      "E se eu não gostei da resolução de alguma questão ou ainda não entendi?",
    content: 'Não se preocupe, após cada questão temos uma área para reportar erro em que você pode especificar o que ficou incerto ou se a questão tem algum erro. Nossa equipe irá avaliar cada solicitação e faremos o possível para melhorar aquele ponto.',
    id: 3,
    color: COLORS.black,
    textColor: COLORS.white
  },
  {
    title: "Como funciona a política da cancelamento?",
    content: "Caso você deseje o cancelamento em até 7 dias do pagamento, você receberá 100% do valor investido, independente do plano assinado. Você poderá cancelar sua assinatura a qualquer momento e seu acesso seguirá ativo até o final do ciclo de pagamento correspondente, bastando solicitar o cancelamento antes da data de renovação.",
    id: 4,
    color: COLORS.gray,
    textColor: COLORS.black

  },
  {
    title:
      "Estou com qualquer dificuldade com relação à utilização do site, como proceder?",
    content: "Qualquer dúvida ou dificuldade em relação ao uso da plataforma, você pode contar com nosso canal aberto via whatsapp ou email.",
    id: 5,
    color: COLORS.blue,
    textColor: COLORS.white
  },
  {
    title: "Como funciona o Revise Rápido?",
    content: "Lá você estuda usando diretamente as alternativas das provas em forma de verdadeiro ou falso, utilizando o nosso filtro diferenciado.",
    id: 6,
    color: COLORS.black,
    textColor: COLORS.white,
    height: '250px'
  },
];
