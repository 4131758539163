export const amountOption = ["15", "20", "30", "50"];
export const timeOption = [
  "30min",
  "1h",
  "1h30",
  "2h",
  "2h30",
  "3h",
  "3h30",
  "4h",
  "4h30",
  "5h",
];
