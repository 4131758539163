import { Pagination as MUIPagination } from "@mui/material";
import { TPaginationProps } from "./types";
import { scrollToTop } from "../../../helpers";

export const Pagination = ({
  itemsPerPage,
  totalItems,
  offset,
  setOffset,
}: TPaginationProps) => {
  const currentPage = Math.max(offset / itemsPerPage + 1, 1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const onPageChange = (page: number) => {
    setOffset((page - 1) * itemsPerPage);
    scrollToTop();
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
  };

  return (
    <MUIPagination
      count={totalPages}
      page={currentPage}
      onChange={handleChange}
      shape="rounded"
      color="primary"
      size="small"
      variant="outlined"
      showFirstButton
      showLastButton
    />
  );
};
