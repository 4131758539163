import { useState } from "react";
import { Collapse } from "react-collapse";
import { Typography, Flex, Button } from "../../../components";

import { arrowUpIcon, successIcon, warningIcon } from "../../../assets";

import { TFeedbackProps, TQuestionImageProps } from "./types";
import * as S from "./styles";

export const QuestionImage = ({ src }: TQuestionImageProps) => {
  if (!src) return null;

  return (
    <span>
      <S.Image src={src} alt="" />
    </span>
  );
};

export const Feedback = ({
  isCorrect = false,
  question,
  onHandleReport,
}: TFeedbackProps) => {
  const [openResponse, setOpenResponse] = useState<boolean>(false);

  const handleShowResponse = () => setOpenResponse((prev) => !prev);

  return (
    <S.FeedbackContainer
      direction="column"
      gap={16}
      width="100%"
      isCorrect={isCorrect}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        style={{ cursor: "pointer" }}
        onClick={handleShowResponse}
      >
        <Flex gap={4} alignItems="center">
          <img src={isCorrect ? successIcon : warningIcon} />

          <Typography weight={500}>
            Resposta {isCorrect ? "correta" : "errada"}!
          </Typography>
        </Flex>
        <S.Icon src={arrowUpIcon} rotate={!openResponse} />
      </Flex>

      {openResponse && (
        <Collapse isOpened={openResponse}>
          <Flex direction="column" gap={16}>
            <Typography weight={400}>{question.comment_correction}</Typography>

            {question.image && (
              <div>
                <S.Image src={question.image} />
              </div>
            )}

            <S.ReportButton onClick={onHandleReport}>
              <S.ReportImg alignItems="center">
                <img src={warningIcon} width={15} height={15} />
              </S.ReportImg>
              Reportar erro
            </S.ReportButton>
          </Flex>
        </Collapse>
      )}
    </S.FeedbackContainer>
  );
};
