import styled from "styled-components";

export const QuestionNumber = styled.div<{ number: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f4f2ff;
  width: 30px;
  height: 30px;
  font-size: 14px;
  color: #6e6893;

  ::before {
    content: "${(props) => props.number}";
  }
`;

export const IconImage = styled.img`
  position: absolute;
  top: -2px;
  right: -5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;
