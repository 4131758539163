import { useState } from "react";

import { checkCircleIcon, errorCircleIcon } from "../../../assets";
import { Tooltip } from "../../../components";
import { TImageRightWrongProps } from "./types";
import * as S from "./styles";

export const QuestionNumber = ({
  number,
  isCorrect,
}: TImageRightWrongProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <S.QuestionNumber number={number}>
        {typeof isCorrect === "boolean" && (
          <Tooltip
            title={`Você respondeu ${
              isCorrect ? "corretamente" : "incorretamente"
            } anteriormente!`}
            placement="right-end"
            open={open}
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
            disableFocusListener
          >
            <S.IconImage
              src={isCorrect ? checkCircleIcon : errorCircleIcon}
              onClick={handleTooltipOpen}
              onMouseLeave={handleTooltipClose}
            />
          </Tooltip>
        )}
      </S.QuestionNumber>
    </div>
  );
};
