import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import FeatureCard from "../../../components/FeatureCard";

import { Container, CardsContainer } from "./styles";

import paper from "../../../assets/paper.svg";
import people from "../../../assets/people.svg";
import edit from "../../../assets/edit.png";
import adjusts from "../../../assets/ajustes.png";
import { AuthContext } from "../../../contexts/auth";
import { ERoutesPath } from "../../../routes";
import { QuestionContext } from "../../../contexts/question";

export const ProfessionalPage: React.FC = () => {
  const navigate = useNavigate();
  const { typeUser } = useContext(AuthContext);
  const { setSelectedQuestion } = useContext(QuestionContext);

  useEffect(() => {
    if (typeUser !== "10") {
      navigate(ERoutesPath.ROOT);
      return;
    }
  }, []);

  return (
    <>
      <Container>
        <p className="title">O que você precisa fazer?</p>
        <p className="subtitle">
          Escolha uma das opções abaixo para acessar um dos serviços da Revise.
        </p>
        <CardsContainer>
          <FeatureCard
            title="Cadastrar questões"
            subtitle="Realizar cadastro de novas questões."
            imageSource={paper}
            onClick={() => {
              setSelectedQuestion(undefined);
              navigate(ERoutesPath.ADMINISTRAR_QUESTOES);
            }}
          />
          <FeatureCard
            title="Atualizar questões"
            subtitle="Edite as questões já cadastradas."
            imageSource={edit}
            onClick={() => navigate(ERoutesPath.QUESTOES)}
          />
          <FeatureCard
            title="Usuários"
            subtitle="Veja e gerencie a lista de todos os seus usuários."
            imageSource={people}
            onClick={() => navigate(ERoutesPath.USUARIOS)}
          />
          <FeatureCard
            title="Relatórios de correções"
            subtitle="Veja a lista de solicitações de correções das questões."
            imageSource={adjusts}
            onClick={() => navigate(ERoutesPath.CORRECAO)}
          />
        </CardsContainer>
      </Container>
    </>
  );
};
