import styled from 'styled-components';

export const Container = styled.div`
  min-height: inherit;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .title {
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
  }
  .subtitle {
    margin-bottom: 36px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`;