import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import * as S from "./styles";
import { Fab } from "@mui/material";

import Tooltip from "@mui/material/Tooltip";
export const FloatingWhatsapp = () => {
  const whatsAppUrl =
    "https://wa.me/5581999526246?text=Ol%C3%A1%2C+estou+entrando+em+contato+pois+tenho+interesse+na+plataforma+para+evoluir+nos+meus+estudos.+Pode+me+ajudar%3F";

  const handleOpenLink = () => {
    window.open(whatsAppUrl, "_blank");
  };

  return (
    <S.FloatingWhatsappContainer>
      <Tooltip
        title="Entre em contato com a nossa equipe agora!"
        placement="top-start"
      >
        <Fab
          color="inherit"
          style={{ color: "#25D366" }}
          onClick={handleOpenLink}
        >
          <WhatsAppIcon />
        </Fab>
      </Tooltip>
    </S.FloatingWhatsappContainer>
  );
};
