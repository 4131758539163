import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from "react";

export type TQuestion = {
  alternative_a: string;
  alternative_b: string;
  alternative_c: string;
  alternative_d: string;
  alternative_e: string;
  alternatives_image: any;
  bank: string;
  comment_correction: string;
  course: string;
  created_at: string;
  description_issue: any;
  difficulty: string;
  feedback: string;
  id: number;
  image: any;
  institution: string;
  level: string;
  matter: string;
  modality: string;
  office: string;
  updated_at: string;
  weight: string;
  year: string;
  description?: string;
  flash_card?: boolean;
  flashcard?: boolean;
  selected_answered?: string;
};

export type TQuestionContext = {
  selectedQuestion: TQuestion | undefined;
  setSelectedQuestion: Dispatch<SetStateAction<TQuestion | undefined>>;
};

export const QuestionContext = createContext<TQuestionContext>(
  {} as TQuestionContext
);

export const QuestionProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [selectedQuestion, setSelectedQuestion] = useState<TQuestion>();

  const contextValue = useMemo(
    () => ({ selectedQuestion, setSelectedQuestion }),
    [selectedQuestion]
  );

  return (
    <QuestionContext.Provider value={contextValue}>
      {children}
    </QuestionContext.Provider>
  );
};
