import styled from "styled-components";
import Button from "../../components/Button";

export const Container = styled.div<{ isModal: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
  gap: 20px;

  width: 100%;
  height: 100%;
  padding: ${({ isModal }) => (isModal ? "unset" : "40px 60px")};

  background: #ffffff;
  @media (max-width: 800px) {
    padding: ${({ isModal }) => (isModal ? "unset" : "40px")};
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  gap: 10px;

  margin-top: 30px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

export const Title = styled.h2<{ isBigger?: boolean }>`
  font-size: ${({ isBigger }) => (isBigger ? "40px" : "24px")};
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  min-height: 56px;
  color: #3a66fb;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;

  @media (max-width: 800px) {
    font-size: ${({ isBigger }) => (isBigger ? "32px" : "24px")};
  }
`;

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  border-radius: 8px;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
  width: 100%;
  padding-bottom: 25px;
`;

export const PlanTitle = styled.div`
  background-color: #3a66fb;
  border-radius: 8px 8px 0 0;
`;

export const PlanBlackContent = styled.div`
  background-color: #000000;
`;

export const ButtonContainer = styled(Button)`
  margin: auto;
`;

export const Text = styled.p<{ size: number; color: string; weight?: number }>`
  font-size: ${({ size }) => `${size}px`};
  font-family: "Montserrat", sans-serif;
  font-weight: ${({ weight = 600 }) => weight};
  color: ${({ color }) => color};
  text-transform: uppercase;

  @media (max-width: 800px) {
    font-size: ${({ size }) => `${size - 8}px`};
  }
`;

export const BackNovemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 20px;
  background-color: #000000;
  width: 100vw;
`;

export const BlackTitle = styled.div<{ isModal: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  flex-direction: ${({ isModal }) => (isModal ? "column" : "row")};

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
