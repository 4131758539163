import { Outlet } from "react-router-dom-v5-compat";

import { Footer } from '../Footer';
import { Header } from '../Header';

import * as S from './styles';

export const Layout = () => (
  <>
    <Header />

    <S.Main>
      <Outlet />
    </S.Main>

    <Footer />
  </>
);
