import styled from "styled-components";

export const TimerContainer = styled.div`
  position: fixed;
  bottom: 20px;

  font-size: 14px;
  color: black;

  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 350px;
  background-color: #fff;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  padding: 16px;
  text-align: center;
  border-radius: 8px;
  z-index: 9999;
`;

export const IconImage = styled.img`
  position: absolute;
  top: -2px;
  right: -5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;
