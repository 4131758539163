import styled from "styled-components";
import { Flex } from "../../components";

export const SubjectItem = styled(Flex)`
  background-color: #ecf0f2;
  border-radius: 8px;
`;

export const MattersContainer = styled.div`
  padding: 16px 24px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    grid-gap: 2px;
  }
`;
