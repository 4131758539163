import styled from "styled-components";

export const ContainerCheckbox = styled.input`
  margin-right: 8px;
  width: 16px;
  height: 16px;
  accent-color: #3a66fb;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #78909d;
  cursor: pointer;
`;