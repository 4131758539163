import styled, { css } from "styled-components";
import { TTagProps } from "./types";

const tagColors = {
  SUCCESS: {
    background: "#CDFFCD",
    color: "#007F00",
  },
  INFO: {
    background: "#7D7BFF",
    color: "#FFFFFF",
  },
  WARNING: {
    background: "#fff3cd",
    color: "#856404",
  },
  ERROR: {
    background: "#f8d7da",
    color: "#721c24",
  },
};

export const StyledTag = styled.span<Pick<TTagProps, "type">>`
  display: inline-block;
  padding: 3px 6px;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  ${(props) =>
    css`
      background-color: ${tagColors[props.type].background};
      color: ${tagColors[props.type].color};
    `}
`;
