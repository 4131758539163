/*
 * Removes a key from localStorage and its sibling expiration key
 * @param key localStorage key to remove
 * @returns Boolean telling if operation succeeded
 */
const removeStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
    localStorage.removeItem(key + "_expiresIn");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
};

/**
 * Retrieves a key from localStorage previously set with setStorage()
 * @param key localStorage key to retrieve
 * @returns The key in case it was found
 * @returns null in case of expired key or failure
 */
export const getStorage = (key: string) => {
  const now = Date.now();

  // set expiration for storage
  let expiresIn = localStorage.getItem(key + "_expiresIn") as unknown as number;
  if (!expiresIn || expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  if (expiresIn < now) {
    // Expired key
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
          key +
          "] from localStorage: " +
          JSON.stringify(e)
      );
      return null;
    }
  }
};

/**
 * Writes a key into localStorage setting an expire time
 * @param key localStorage key to be set
 * @param value value for the localStorage key to be set
 * @param expires An optional parameter to the number of seconds from now to expire the key
 * @returns Boolean telling if the operation succeeded
 */
export const setStorage = (key: string, value: string, expires?: number) => {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  } else {
    expires = Math.abs(expires); // make sure it's positive
  }

  const now = Date.now();
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule as unknown as string);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
        key +
        "] in localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
};
