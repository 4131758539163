import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: 50px;

  @media (max-width: 550px) {
    padding: 25px;
  }
`;

export const ColumnContainer = styled.div<{ columns?: number }>`
  display: grid;
  grid-gap: 16px;
  ${({ columns = 2 }) => css`
    grid-template-columns: repeat(${columns}, 1fr);
  `}

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledForm = styled.form``;

export const ContainerRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 16px;
  margin-bottom: 16px;
`;

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Inline = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const CheckboxContainer = styled.div`
  font-size: 14px;
  min-width: fit-content;
`;
