import styled from "styled-components";

export const ContentFilter = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 6fr 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 24px;
  padding: 10px 0;

  @media (max-width: 800px) {
    padding-left: 8px;
    padding-right: 8px;
    grid-template-columns: 1fr;
  }
`;
