import { InputHTMLAttributes } from "react";
import { ContainerInput } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  placeholder: string;
  color?: "white" | "gray";
}

/**
 * @deprecated To be refactor
 */
const Search = ({
  name,
  placeholder,
  color = "white",
  ...rest
}: InputProps) => {
  return (
    <ContainerInput
      className="Input"
      name={name}
      id={name}
      placeholder={placeholder}
      color={color}
      autoComplete="off"
      type="hidden"
      {...rest}
    />
  );
};

export default Search;
