import { questionMarkIcon, closeCircleIcon } from "../../../assets";
import { Flex, Typography } from "../../../components";
import { ECircleStatus, TQuestionMenuProps } from "./types";
import * as S from "./styles";
import { useIsMobile } from "../../../hooks";
import { useEffect, useState } from "react";

export const QuestionMenu = ({
  onSelectQuestion,
  selectedQuestions,
}: TQuestionMenuProps) => {
  const isMobile = useIsMobile();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSelectQuestion = (num: number) => {
    onSelectQuestion(num);
    setIsOpen(false);
  };

  const handleChangeOpen = () => {
    if (isMobile) setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!isMobile) {
      setIsOpen(true);
    }
  }, [isMobile]);

  return (
    <S.QuestionNumbersContainer>
      <S.Header
        gap={16}
        alignItems="center"
        onClick={handleChangeOpen}
        isClose={!isOpen}
        style={{ cursor: `${isMobile ? "pointer" : "unset"}` }}
      >
        <Flex gap={16} alignItems="center" width="100%">
          <img src={questionMarkIcon} />
          {isOpen && <Typography color="#FFFFFF">Questões</Typography>}
        </Flex>
        {isOpen && isMobile && <img src={closeCircleIcon} />}
      </S.Header>

      {isOpen && (
        <S.CircleContainer>
          {selectedQuestions &&
            Object.values(selectedQuestions).map((data, key) => {
              const number = key + 1;
              return (
                <S.Circle
                  key={number}
                  status={data.alternative ? ECircleStatus.DONE : undefined}
                  onClick={() => handleSelectQuestion(number)}
                >
                  {data.alternative ? "✓" : number}
                </S.Circle>
              );
            })}
        </S.CircleContainer>
      )}
    </S.QuestionNumbersContainer>
  );
};
