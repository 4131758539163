import styled from "styled-components";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const ContainerButton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 8px;
`;

export const CheckboxContainer = styled.div`
  width: 100%;
  display: inline;
  font-size: 14px;

  text-align: justify;
`;
