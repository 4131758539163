import { TextField, FormControl } from "@mui/material";

interface InputTextProps {
  label: string;
  name: string;
  formik: any;
  isNumeric?: boolean;
  minLength?: number;
  maxLength?: number;
}

export const InputText = ({
  label,
  name,
  formik,
  isNumeric = false,
  minLength,
  maxLength,
}: InputTextProps) => {
  const renderError = () => {
    const error = formik.errors[name];
    if (typeof error === "string") {
      return error;
    }
    if (Array.isArray(error) && error.length > 0) {
      return error.join(", ");
    }
    return null;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;

    if (isNumeric) {
      inputValue = inputValue.replace(/\D/g, "");
    }

    formik.setFieldValue(name, inputValue);
  };

  return (
    <FormControl sx={{ width: "100%" }} error={!!formik.errors[name]}>
      <TextField
        variant="standard"
        label={label}
        id={`text-input-${name}`}
        name={name}
        value={formik.values[name] || ""}
        onChange={handleInputChange}
        onBlur={formik.handleBlur}
        error={!!formik.errors[name]}
        helperText={renderError()}
      />
    </FormControl>
  );
};
