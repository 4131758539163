import styled, { css } from 'styled-components';

export const QuestionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Question = styled.div`
  padding: 24px;
  background-color: #ECF0F2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  
  border-radius: 8px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`

export const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;
  align-items: center;

  @media (max-width: 500px) {
    justify-content: space-between;
  }
`

export const QuestionsStatus = styled.span<{ color?: string }>`
  display: flex;
  border-radius: 8px;
  padding: 8px;
  ${({ color = "#3a66fb" }) => css` 
    border:  1px solid ${color}; 
  `}
`

export const Center = styled.div`
  display: flex;
  justify-content: center;
`