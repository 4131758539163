import { PropsWithChildren } from "react";
import { TTypographyProps } from "./types";

import * as S from "./styles";

export const Typography = ({
  color = "#000000",
  align = "left",
  style,
  weight = 400,
  family = "Poppins",
  size = 16,
  children,
}: PropsWithChildren<TTypographyProps>) => (
  <S.Container
    color={color}
    style={style}
    align={align}
    weight={weight}
    family={family}
    size={size}
  >
    {children}
  </S.Container>
);
