import styled from "styled-components";
import { Link } from "react-router-dom-v5-compat";

export const BreadcrumbsContainer = styled.nav`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  font-family: "Poppins", sans-serif;

  @media (max-width: 550px) {
    font-size: 11px;
  }
`;

export const BreadcrumbLink = styled(Link)`
  color: #000000;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const BreadcrumbText = styled.span`
  color: #6c757d;
`;

export const BreadcrumbSeparator = styled.span`
  margin: 0 8px;
  color: #6c757d;
`;
