import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

import { capitalizeWords, toUpperCase } from "../../../helpers";
import { TMultipleSelectProps } from "./types";

export const MultipleSelect = ({ label, name, options, formik, acceptOne, upperCase }: TMultipleSelectProps) => {

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel id={`multiple-${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`multiple-${name}-label`}
        id={`multiple-${name}`}
        multiple={!acceptOne}
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        disabled={options.length === 0}
        input={<Input />}
        renderValue={acceptOne
          ? (selected) => (selected)
          : (selected: Array<string>) => (
            selected.map((value) => upperCase ? toUpperCase(value) : capitalizeWords(value)) as string[]
          ).join(", ")}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={acceptOne
              ? formik.values[name] === option
              : formik.values[name].includes(option)} />
            <ListItemText primary={upperCase ? toUpperCase(option) : capitalizeWords(option)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
};
