import { Breadcrumbs, Flex, Typography } from "../../../components";

import { TPageTemplateProps } from "./types";
import * as S from "./styles";

export const PageTemplate = ({
  title,
  breadcrumbsItems,
  children,
}: TPageTemplateProps) => (
  <S.Container>
    <S.Header>
      <Flex direction="column" gap={4}>
        <Typography family="Montserrat" size={32} weight={600}>
          {title}
        </Typography>

        {breadcrumbsItems && <Breadcrumbs items={breadcrumbsItems} />}
      </Flex>
    </S.Header>

    {children}
  </S.Container>
);
