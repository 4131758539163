import styled from 'styled-components';

interface ContainerInputProps {
  color: 'white' | 'gray';
}

export const ContainerInput = styled.input<ContainerInputProps>`
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  background: ${(props) => props.color === 'white' ?'#FFF':'#ECF0F2'};
  border-radius: 8px;
  font-family: inherit;
  border: 1px solid ${({theme}) => theme.colors.blue_4};
  &::placeholder {
    font-size: 12px;
    line-height: 16px;
    color: #78909D;
  }
`;