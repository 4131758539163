import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom-v5-compat";
import { CircularProgress } from "@material-ui/core";

import {
  Input,
  ModalContainer,
  Link,
  ForgotPasswordModal,
  Typography,
  Button,
} from "../../../components";
import { AuthContext } from "../../../contexts/auth";

import { ERoutesPath } from "../../../routes";

import { TLoginModalProps } from "./types";
import * as S from "./styles";

export const LoginModal = ({ modalDismiss }: TLoginModalProps) => {
  const navigate = useNavigate();

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");

  const { signIn, typeUser } = useContext(AuthContext);

  useEffect(() => {
    if (typeUser === "1") {
      modalDismiss();
      navigate(ERoutesPath.ROOT);
    }
  }, [typeUser]);

  const handleSubmit = async (email: string, password: string) => {
    try {
      setIsLoading(true);
      setLoginError("");
      await signIn(email, password);
      modalDismiss();
    } catch (err) {
      setLoginError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      loginEmail: "",
      loginPassword: "",
    },
    onSubmit: (values) => {
      handleSubmit(values.loginEmail, values.loginPassword);
    },
  });

  const handleCloseModal = () => {
    modalDismiss();
  };

  return (
    <>
      {showForgotPasswordModal ? (
        <ForgotPasswordModal
          modalDismiss={() => setShowForgotPasswordModal(false)}
        />
      ) : (
        <ModalContainer
          modalDismiss={handleCloseModal}
          title="Entrar na Revise"
          subtitle="Insira nos campos abaixo o e-mail e a senha"
        >
          <S.FormContainer onSubmit={formik.handleSubmit} autoComplete="off">
            <Input
              name="loginEmail"
              type="email"
              placeholder="Insira seu e-mail"
              onChange={formik.handleChange}
              value={formik.values.loginEmail}
              color="gray"
              required
            />
            <Input
              name="loginPassword"
              type="password"
              placeholder="Senha"
              onChange={formik.handleChange}
              value={formik.values.loginPassword}
              color="gray"
              required
            />
            {loginError && !isLoading && (
              <Typography
                family="Montserrat"
                size={12}
                weight={300}
                color="red"
                style={{ width: "100%" }}
              >
                {loginError}
              </Typography>
            )}

            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Button type="submit">ENTRAR</Button>

                <Link
                  onClick={() => {
                    setShowForgotPasswordModal(true);
                  }}
                >
                  Esqueceu a senha?
                </Link>
              </>
            )}
          </S.FormContainer>
        </ModalContainer>
      )}
    </>
  );
};
