import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Container = styled.div`
  margin: 0 auto;
  background: #ffffff;
  font-family: "open sans";
  font-size: 0.85em;
  line-height: 1.6em;
  border-radius: 15px;
  width: 50%;
  height: auto;
  color: #000000;
  padding: 20px;
  position: relative;
  margin-top: 30px;
  border: 5px solid #95abfc;
  box-shadow: 8px 8px #000000;

  @media (max-width: 480px) {
    width: 90%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Text = styled.div`
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
`;

export const TextRight = styled.div`
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: right;
  font-style: italic;
`;
