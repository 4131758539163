import styled, { css } from "styled-components";

export const Link = styled.a<{ color: string }>`
  font-family: Poppins;
  font-size: 14px;
  text-decoration-line: underline;

  ${({ color }) => css`
    color: ${color};
  `}
`;
