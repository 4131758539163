

import { PropsWithChildren } from 'react'

import { TLinkProps } from './types'
import * as S from './styles'



export const Link = ({ children, color = '#6b8dfc', ...restProps }: PropsWithChildren<TLinkProps>) => (
  <S.Link color={color} {...restProps}>{children}</S.Link>
)