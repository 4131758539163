import { PLAN_VALUES } from "./helper";
import { Button, Flex } from "../../components";

import * as S from "./styles";

export const PlanValues = ({ isModal = false }) => {
  const openLink = (link: string) => {
    window.open(link);
  };

  return (
    <S.Container id="planos" isModal={isModal}>
      {!isModal && <S.Title isBigger>Planos</S.Title>}

      <S.CardsContainer>
        {PLAN_VALUES.map((plan) => (
          <S.PlanContainer key={plan.title}>
            <S.PlanTitle>
              <S.Title style={{ color: "#FFFFFF" }}>{plan.title}</S.Title>
            </S.PlanTitle>

            <ul style={{ listStyle: "none", padding: "0 20px" }}>
              {plan.itens.map((item) => (
                <S.List key={item}>{item}</S.List>
              ))}
            </ul>

            <S.Title isBigger>{plan.price}</S.Title>

            <Flex justifyContent="center">
              <Button onClick={() => openLink(plan.link)}>ASSINE AGORA</Button>
            </Flex>
          </S.PlanContainer>
        ))}
      </S.CardsContainer>
    </S.Container>
  );
};
