import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-left: 36px;
  padding-right: 36px;


  .question{
    display: grid;
      padding-top: 100px;
      width: 100%;
      margin-bottom: 40px;
      border-bottom: 1px solid #DFDFDF;
      padding-bottom: 16px;

      @media (max-width: 800px) {
        margin-bottom: 0px;
        padding-left: 16px;
        padding-bottom: 16px;
      }

      h3{
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 20px;
        color: #727272;

        @media (max-width: 800px) {
          font-size: 13px;
        }

      }
  }
  .themes {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 60px;

    @media (max-width: 800px) {
      margin-top: 50px;
    }

    .themesSuccess{
      display: flex;
      width: 100%;
      background: #FFCE76;
      text-align: center;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border-style: none;
      border-color: #727272;
      padding: 30px;
      margin-top: 40px;


      @media(max-width: 800px) {
        width: 100%;
      }

      p{
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        color: black;
      
        @media (max-width: 800px) {
          font-size: 15px;
        }
      }
    }

    .themesFailed{
      display: flex;
      width: 100%;
      margin-top: 50px;
      background: #F18A8A;
      text-align: center;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border-style: none;
      border-color: #F18A8A;
      padding: 40px;

      @media(max-width: 800px) {
        width: 100%;
      }

      p{
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        color: #ECF0F2;

        @media (max-width: 800px) {
          font-size: 15px;
        }
      }
    }
  }
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 400px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 200px;
`;


export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background: #FFFFFF;
  border-radius: 16px;
  width: 100%;
  margin: 24px 0px;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;

    width: 136px;
    height: 24px;
    border-radius: 8px;

    border: none;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;

    color: ${({ theme }) => theme.colors.gray_2};
  }
`;

export const TextWrapper = styled.div`
  margin: 0 16px;
  flex: 1;
`;

export const Title = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #263136;
`;

export const Subtitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.blue_gray_1};
  padding-left: 8px;
  width: 80%;
`;

export const Address = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #445963;
`;

interface DataContainerProps {
  hasBackground?: boolean;
}

export const DataContainer = styled.div<DataContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;

  position: static;
  min-width: fit-content;
  width: 180px;
  height: 56px;
  background: ${props => props.hasBackground ? '#ECF0F2' : null};
  border-radius: 4px;

  margin-left: 8px;
`;

export const DataValue = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Blue Grey/Blue Grey 700 */

  color: #445963;
`;

export const ButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;

  width: 106px;
  height: 40px;

  border: none;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  color: ${({ theme }) => theme.colors.gray_2};
`;

export const ContentDetails = styled.div`
    width: 100%;
`;

