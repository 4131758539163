import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    -webkit-font-smoothing: antialiased !important;
    font-family: 'Poppins', sans-serif;
    background: #F9F9F9;
  }

  select, option {
    font: -moz-pull-down-menu;
  }

  button {
    cursor: pointer;
    background: none;
  }

  a {
    cursor: pointer;
  }

  p {
    user-select: none;

    &.title {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: #263136;
      margin-bottom: 8px;
    }

    &.subtitle {
      font-size: 16px;
      line-height: 24px;
      color: #78909D;
      margin-bottom: 8px;
    }
  }
`;
