import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
  background: #fff;
  height: 60px;
`;

export const ContentLogo = styled.div`
  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const ContentListButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;

  margin-right: 40px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ProfileTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SignOutButton = styled.button`
  border: none;
  font-size: 13px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  color: #000;
  background-color: transparent;
`;

export const TutorialWrapper = styled.span`
  margin-right: 16px;
  @media (min-width: 800px) {
    display: none;
  }
`;
