import styled from "styled-components";

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000050;
  z-index: 15;
`;

export const Container = styled.div`
  z-index: 200;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(26, 26, 26, 0.2);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  min-width: 500px;

  @media (max-width: 800px) {
    padding: 24px 16px;
    min-width: unset;
    width: 85%;
  }
`;
