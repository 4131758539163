import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 4%;
  min-width: fit-content;
  width: 100%;
  padding-bottom: 16px;
  margin-top: 20px;
`;

export const TextHeader = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 4px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-top: 110px;
  padding-right: 70px;
  padding-left: 60px;
`;

export const ContentSearch = styled.div`
 display: flex;
`;

export const ContentButton = styled.div`
 display: flex;
`;

export const InputContainer = styled.div`
  width: 30%;
  height: 48px;
  border-radius: 10px;
  position: relative;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 400px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 200px;
`;

