import { Typography } from "../../../components";
import { TModalContainerProps } from "./types";
import { closeIcon } from "../../../assets";

import * as S from "./styles";

export const ModalContainer = ({
  children,
  title,
  subtitle,
  modalDismiss,
}: TModalContainerProps) => (
  <S.Background onClick={() => modalDismiss()}>
    <S.Container onClick={(e) => e.stopPropagation()}>
      <img
        src={closeIcon}
        onClick={modalDismiss}
        style={{ position: "absolute", right: 16, top: 16, cursor: "pointer" }}
      />

      <Typography family="Montserrat" size={24} weight={500} align="center">
        {title}
      </Typography>
      {subtitle && (
        <Typography color="#78909d" align="center">
          {subtitle}
        </Typography>
      )}
      {children}
    </S.Container>
  </S.Background>
);
