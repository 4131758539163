import { useFormik } from "formik";
import { useState } from "react";

import { CircularProgress } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Input, ModalContainer, Link } from "../../../components";
import Button from "../../Button";
import { maskPhone } from "../../../utils/masks";
import api from "../../../services/api";

import { TRegisterModalProps } from "./types";
import * as S from './styles'
import { TERMS_CONDITIONS } from "../../../constants";

export const RegisterModal = ({
  modalDismiss,
  handleRegisterUser,
}: TRegisterModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [showPasswords, setShowPasswords] = useState({
    password: false,
    confirm: false,
  });
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      cellPhone: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      // handleSubmit(values.loginEmail, values.loginPassword);
    },
  });

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const body = {
        name: formik.values.fullName,
        email: formik.values.email,
        cellphone: formik.values.cellPhone,
        password: formik.values.password,
      };

      await api.post("/api/createAccount", body);

      alert("Cadastro realizado com sucesso!");
      modalDismiss();
      if (handleRegisterUser) handleRegisterUser();
    } catch (err: any) {
      if (err.response.status === 403) {
        alert(`Desculpe. ${err.response.data.details}`);
      } else if (err.response.status === 422) {
        const vectorError = Object.entries(err.response.data.errors);
        const vectorErrorLength = Object.keys(err.response.data.errors).length;
        var errors = "";
        for (var count = 0; count < vectorErrorLength; count++) {
          errors += "- " + vectorError[count][1] + "\n";
        }
        if (vectorErrorLength > 0) {
          alert(`Ops! um ou mais itens precisam de atenção!\n ${errors}`);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalContainer
      modalDismiss={modalDismiss}
      title="Cadastre-se na Revise"
    >
      <S.FormContainer onSubmit={formik.handleSubmit}>
        <Input
          type="name"
          name="fullName"
          placeholder="Informe seu nome completo *"
          onChange={formik.handleChange}
          value={formik.values.fullName}
          autoCapitalize="words"
          color="gray"
        />
        <Input
          name="email"
          type="email"
          placeholder="Informe seu e-mail *"
          onChange={formik.handleChange}
          value={formik.values.email}
          color="gray"
        />

        <Input
          name="cellPhone"
          type="phone"
          placeholder="Informe seu número de contato (81)99999-9999"
          maxLength={14}
          onChange={(e) => {
            formik.setFieldValue("cellPhone", maskPhone(e.target.value));
          }}
          value={formik.values.cellPhone ? formik.values.cellPhone : ""}
          color="gray"
        />

        <Input
          type={showPasswords.password ? "text" : "password"}
          name="password"
          placeholder="Senha *"
          onChange={formik.handleChange}
          value={formik.values.password}
          color="gray"
          icon={showPasswords.password ? VisibilityOffIcon : VisibilityIcon}
          onIconClick={() =>
            setShowPasswords((prev) => ({
              ...prev,
              password: !prev.password,
            }))
          }
        />

        <Input
          type={showPasswords.confirm ? "text" : "password"}
          name="confirmPassword"
          placeholder="Repetir senha *"
          onChange={formik.handleChange}
          value={formik.values.confirmPassword}
          color="gray"
          icon={showPasswords.confirm ? VisibilityOffIcon : VisibilityIcon}
          onIconClick={() =>
            setShowPasswords((prev) => ({
              ...prev,
              confirm: !prev.confirm,
            }))
          }
        />

        <S.CheckboxContainer>
          <Checkbox
            checked={acceptedTerms}
            onChange={() =>
              setAcceptedTerms((prev) => !prev)
            }
          />
          Declaro que li e autorizo o uso de meus dados de acordo com os <Link
            href={TERMS_CONDITIONS}
            rel='noreferrer'
            target="_blank">Termos e condições</Link>.
        </S.CheckboxContainer>


        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <S.ContainerButton>
              <Button
                isSecondary={false}
                type="submit"
                disabled={!acceptedTerms}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Confirmar cadastro
              </Button>
              <Button
                isSecondary={true}
                type="submit"
                onClick={() => {
                  modalDismiss();
                }}
              >
                Cancelar
              </Button>
            </S.ContainerButton>
          </>
        )}
      </S.FormContainer>
    </ModalContainer>
  );
};
