import React, { forwardRef } from "react";

import { TFlexProps } from "./types";
import * as S from "./styles";

export const Flex = forwardRef<HTMLDivElement, TFlexProps>(
  (
    {
      direction,
      justifyContent,
      alignItems,
      gap,
      height,
      maxHeight,
      minHeight,
      width,
      maxWidth,
      minWidth,
      style,
      className,
      onClick,
      children,
    },
    ref
  ) => {
    return (
      <S.FlexContainer
        ref={ref} // Passe a ref para o elemento
        direction={direction}
        justifyContent={justifyContent}
        alignItems={alignItems}
        gap={gap}
        height={height}
        maxHeight={maxHeight}
        minHeight={minHeight}
        width={width}
        maxWidth={maxWidth}
        minWidth={minWidth}
        style={style}
        className={className}
        onClick={onClick}
      >
        {children}
      </S.FlexContainer>
    );
  }
);

Flex.displayName = "Flex";
