import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Container = styled.div`
  padding: 50px;
  background-color: #3a66fb;

  .slick-prev:before,
  .slick-next:before {
    color: #ffffff;
  }
`;

export const Title = styled.h2`
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  color: #ffffff;
  margin: 24px 0;

  @media (max-width: 800px) {
    font-size: 20px;
  }
`;
