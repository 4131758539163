import Cookies from "js-cookie";

export const setToken = (token: string) => {
  Cookies.set("authToken", token, {
    expires: 7,
    secure: true,
    sameSite: "strict",
  });
};

export const getToken = (): string | undefined => {
  return Cookies.get("authToken");
};

export const removeToken = () => {
  Cookies.remove("authToken");
};
