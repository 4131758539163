import { useState, useEffect } from "react";

export const useIsMobile = () => {
  const mobileBreakpoint = 768;

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < mobileBreakpoint
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < mobileBreakpoint);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};
