import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { Flex, PageTemplate } from "../../../components";

import { AuthContext } from "../../../contexts/auth";
import { ERoutesPath } from "../../../routes";

import { Chart } from "react-google-charts";
import { Counter } from "./Counter";

import * as S from "./styles";
import { SimulatesList } from "./SimulatesList";

export const SimulateMainPage = () => {
  const navigate = useNavigate();
  const { isPaidActive, isUserLogged, user } = useContext(AuthContext);

  useEffect(() => {
    if (!isUserLogged || !isPaidActive) {
      navigate(ERoutesPath.ROOT);
    }
  }, []);

  const data = [
    ["", "Acertos", "Erros"],
    ["Radiologia clinica", 50, 50],
    ["Radiologia clinica", 85, 15],
    ["Clinica ", 73, 27],
    ["Clinica 2", 89, 11],
  ];

  const options = {
    chart: {
      title: "Seu desempenho",
      subtitle: "Últimos simulados",
    },
    colors: ["#7DC579", "#FF8181"],
    legend: { position: "left" },
    // bars: "horizontal",
  };

  const practiceTestBreadcrumb = [
    { label: "Página inicial", route: ERoutesPath.ROOT },
    {
      label: "Simulados",
    },
  ];

  return (
    <PageTemplate title="Simulados" breadcrumbsItems={practiceTestBreadcrumb}>
      <Flex direction="column" gap={16}>
        {/* <S.ChartCounterContainer>
          <Flex width="100%">
            <Chart
              chartType="Bar"
              data={data}
              options={options}
              width="100%"
              height="100%"
            />
          </Flex>

          <Flex width="100%" gap={16}>
            <Counter
              title="Questões resolvidas"
              subtitle="Últimos simulados"
              counter={341}
            />

            <Counter
              title="Simulados resolvidos"
              subtitle="Todos os simulados"
              counter={6}
            />
          </Flex>
        </S.ChartCounterContainer> */}

        <SimulatesList />
      </Flex>
    </PageTemplate>
  );
};

// https://nivo.rocks/bar/

// https://www.chartjs.org/docs/latest/
// https://react-chartjs-2-two.vercel.app/examples/vertical-bar-chart

// https://airbnb.io/visx
