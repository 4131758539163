export const feedbackData = [
  {
    message: `Olá!
  Estou super satisfeita com o investimento, achei o custo benefício excelente!
  A plataforma ainda não usei pra valer, mas o pouco que deu pra investigar nela já foi incrível! Estou amando o conteúdo do instagram, e, principalmente os do close friends. Maneira rápida e eficaz de revisar um conteúdo no dia-a-dia e destacar alguns pontos que ainda precisam ser estudados.
  Sugestão: fazer um pacote anual da plataforma/benefício de renovação do semestral heheheheheheh
  Tenho indicado vocês sempre, principalmente mandando os storys de vocês para os colegas! O conteúdo de vocês diário do instagram é ouro!!!`,
    name: "Carolina Vengrus - Residente de radiologia",
  },
  {
    message:
      "A plataforma me ajudou bastante em uma prova de r4 que fiz recentemente. Parei um pouco os estudos direcionado para provas, mas retornarei em dezembro focando na prova de título do cbr e pretendo zerar a plataforma dessa vez!!",
    name: "Caio Liguori",
  },
  { message: "Essa plataforma era tudo que eu precisava!" },
  {
    message:
      "Tô achando a plataforma bem prática e achei o valor investido bem justo. Indicaria para outras pessoas sim.",
  },
  {
    message:
      "O filtro de questões tá bem legal, inclusive usei pra colocar umas questões no final do meu seminário da residência que vou apresentar",
  },
  {
    message:
      "Oii! Então, eu tô gostando muito de usar a plataforma pra estudar mesmo. O filtro realmente me ajuda a organizar meu estudo por área, sinto que fui mais produtiva nesses últimos dias. E sobre se eu indicaria, inclusive, já indiquei para alguns colegas kkk",
  },
  {
    message:
      "Dos melhores amigos eu to amando. Várias coisas que nunca vi na vida. Eu gosto porque você vai aprendendo com as respostas depois.",
  },
  {
    message:
      "Tô satisfeito sim. Eu estava procurando algo mais ou menos nesse sentido pra prova e os conteúdos do Instagram também estão ajudando",
  },
  {
    message:
      "Bacana!! A plataforma me ajudou bastante em uma prova de r4 que fiz recentemente. Parei um pouco os estudos direcionado para provas, mas retornarei em dezembro focando na prova de título do cbr e pretendo zerar a plataforma dessa vez!!",
    name: "Caio Liguori - Assinante",
  },
];
