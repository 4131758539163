import styled, { css } from "styled-components";
import { Flex } from "../../components";
import { TCardSideProps, TFlipCardProps } from "./types";

const defineBorderColor = ({ side, isTrue }: TCardSideProps) => {
  if (side === "front") return "#3A66FB";
  if (isTrue === true) return "#7DC579";
  if (isTrue === false) return "#FF8181";

  return "#3A66FB";
};

export const FlashDescriptionContainer = styled(Flex)`
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
`;

export const FlipCard = styled.div<TFlipCardProps>`
  perspective: 1000px;

  width: 100%;
  max-width: 450px;
  min-width: 450px;
  height: 400px;
  position: relative;

  @media (max-width: 500px) {
    width: 100%;
    max-width: 350px;
    min-width: 350px;
    height: 400px;
  }

  .inner-card {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 0.8s ease-in-out;
    transform: ${(props) =>
      props.isFlipped ? "rotateY(180deg)" : "rotateY(0deg)"};
  }

  .front,
  .back {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;
  }

  .back {
    transform: rotateY(180deg);
  }
`;

export const CardSide = styled.div<TCardSideProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px 20px;

  background-color: #ffffff;
  border-radius: 8px;
  border: 2px solid;
  border-color: #3a66fb;
  border-color: ${(props) => defineBorderColor(props)};

  transform: ${(props) => (props.side === "back" ? "rotateY(180deg)" : "none")};
`;

export const FlipImg = styled.img<{ isLeft?: boolean }>`
  position: absolute;
  top: 5px;
  ${({ isLeft = false }) => css`
    left: ${isLeft ? "5px" : "unset"};
    right: ${isLeft ? "unset" : "5px"};
  `}
`;

export const Button = styled.button<{ color: string }>`
  display: flex;
  min-width: 125px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 6px;

  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;

  ${({ color }) => css`
    background-color: ${color};
  `}
`;

export const ReportButton = styled.button`
  color: #ff8181;
  padding: 4px;
  border: unset;
`;
