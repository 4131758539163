import styled from "styled-components";

export const Card = styled.div`
  width: inherit;
  height: inherit;

  transform-style: preserve-3d;
  transition: transform 0.9s;
  cursor: pointer;

  &:hover {
    transform: rotateY(180deg);
  }
`;

const CardFace = styled.div<{ color: string; textColor: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: ${({ color }) => `${color}`};
  color: ${({ textColor }) => `${textColor}`};

  border-radius: 16px;
`;

export const FrontFace = styled(CardFace)`
  font-size: 20px;
  display: flex;
  align-items: flex-end;
  padding: 30px 15px;
`;

export const BackFace = styled(CardFace)`
  transform: rotateY(180deg);
  padding: 10px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;
