import { scrollToTop } from "../../helpers";
import { ContainerUl } from "./styles";

import { Pagination as PaginationUI } from "@mui/material";

interface PaginationProps {
  itemsPerPage: number;
  totalItems: number;
  offset: number;
  setOffset: (offset: number) => void;
}

/**
 * @deprecated use from 'components' instead
 */
const Pagination = ({
  itemsPerPage,
  totalItems,
  offset,
  setOffset,
}: PaginationProps) => {
  const currentPage = Math.max(offset / itemsPerPage + 1, 1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const onPageChange = (page: number) => {
    setOffset((page - 1) * itemsPerPage);
    scrollToTop();
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
  };

  return (
    <ContainerUl>
      <PaginationUI
        count={totalPages}
        page={currentPage}
        onChange={handleChange}
        shape="rounded"
        color="primary"
        size="small"
      />
    </ContainerUl>
  );
};

export default Pagination;
