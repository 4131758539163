import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
`;

export const Form = styled.form`
  padding-bottom: 3vh;
`;

export const Content = styled.div`
  width: 100%;
  padding: 3vh 2vh;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    padding-left: 8px;
    padding-right: 8px;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const CheckboxContainer = styled.div`
  grid-column: span 3;

  @media (max-width: 800px) {
    grid-column: span 2;
  }

  @media (max-width: 500px) {
    grid-column: span 1;
  }
`;
