export enum EApiPath {
  ADD_QUESTION = "api/addQuestion",
  UPDATE_QUESTION = "api/updateQuestion",
}

export enum EApiUploadImage {
  ADD_IMAGE = "api/addImage",
  ADD_IMAGE_DESCRIPTION = "api/addImageDescription",
  ADD_IMAGE_ALTERNATIVES = "api/addImageAlternatives",

  UPDATE_IMAGE = "api/updateImage",
  UPDATE_IMAGE_DESCRIPTION = "api/updateImageDescription",
  UPDATE_IMAGE_ALTERNATIVES = "api/updateImageAlternatives",
}
