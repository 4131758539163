
import * as S from './styles';
import { TInputProps } from './types';

export const Input = ({ name, placeholder, color = 'white', icon: Icon, onIconClick, ...rest }: TInputProps) => (
  <S.Container color={color}>
    <S.ContainerInput
      className='Input'
      name={name}
      id={name}
      placeholder={placeholder}
      color={color}
      autoComplete="off"
      {...rest}
    />
    {Icon && <S.IconWrapper onClick={onIconClick && onIconClick}><Icon /></S.IconWrapper>}
  </S.Container>
);

