import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #78909d;
  border-radius: 8px;
  background-color: #ecf0f2;
`;

export const ContainerSelect = styled.select`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;

  border: 1px solid #78909d;
  border-radius: 8px;
  background-color: #ecf0f2;

  border: unset;
  text-align-last: left;
  font-family: inherit;

  color: black;

  appearance: none;
`;

export const Option = styled.option``;

export const IconWrapper = styled.div`
  display: flex;
`;
