import styled from "styled-components";
import backgroundBox from "../../../assets/background/boxDoubt.png";

export const Container = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #3a66fb;

  padding: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 20px 10%;
`;

export const HelperContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 250px;
  width: 390px;

  background-image: url(${backgroundBox});
  background-size: cover;

  gap: 15px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ContainerInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ContainerInline = styled.div`
  display: flex;
  gap: 12px;
`;
