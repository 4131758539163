import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import CircularProgress from "@material-ui/core/CircularProgress";

import BackButton from "../../../components/BackButton";
import Button from "../../../components/Button";
import ListItem from "../../../components/ListItem";
import Pagination from "../../../components/Pagination";
import Search from "../../../components/Search";

import { AuthContext } from "../../../contexts/auth";

import api from "../../../services/api";

import defaultAvatar from "../../../assets/defaultAvatar.svg";
import {
  Container,
  ButtonsRow,
  ContentSearch,
  ContentButton,
  ContainerLoading,
} from "./styles";
import { ERoutesPath } from "../../../routes";

const ITEMS_PER_PAGE = 10;

export const ClientsPage: React.FC = () => {
  const navigate = useNavigate();
  const { typeUser } = useContext(AuthContext);

  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (typeUser !== "10") {
      navigate(ERoutesPath.ROOT);
      return;
    }

    setSearch("");
    handleGetBalance();
  }, []);

  useEffect(() => {
    let searchParam = search.trim();

    if (searchParam === "") {
      setPageData(fullData);
    } else {
      // eslint-disable-next-line array-callback-return
      var newListUsers = data.filter((value: any) => {
        if (
          value.name.toLowerCase().indexOf(searchParam.toLowerCase()) !== -1
        ) {
          return value;
        } else if (
          value.cpf.toLowerCase().indexOf(searchParam.toLowerCase()) !== -1
        ) {
          return value;
        } else if (
          value.email.toLowerCase().indexOf(searchParam.toLowerCase()) !== -1
        ) {
          return value;
        }
      });
      setPageData(newListUsers.slice(offset, offset + ITEMS_PER_PAGE));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (data) {
      setPageData(data.slice(offset, offset + ITEMS_PER_PAGE));
      setFullData(data.slice(offset, offset + ITEMS_PER_PAGE));
    }
  }, [data, offset]);

  const handleUpdateDetailsUser = async (objectUser: any) => {
    setIsLoading(true);

    try {
      const body = {
        idAccount: objectUser.id,
      };

      await api.post(`/api/accountAccess`, body);
      alert("Conta do usuário atualizada com sucesso!");
      handleGetBalance();
    } catch (err) {
      alert(
        "Desculpe. Erro interno em nossos servidores, tente novamente mais tarde."
      );
    }
  };

  const handleGetBalance = async () => {
    setIsLoading(true);
    try {
      const apiResponse = await api.get(`/api/accounts`);

      let input = apiResponse.data;

      let accountSortedByCreatedAt = input.sort((a: any, b: any) =>
        b.created_at.localeCompare(a.created_at)
      );

      setData(accountSortedByCreatedAt);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <ButtonsRow>
        <ContentSearch>
          <Search
            name="search"
            type="name"
            placeholder="Pesquisar: Nome, email ou CPF"
            onChange={(object) => {
              setSearch(String(object.target.value));
            }}
            value={search}
            color="white"
          />
        </ContentSearch>
        <ContentButton>
          <BackButton
            type="button"
            onClick={() => navigate(ERoutesPath.INICIO_PROFISSIONAL)}
          >
            Voltar
          </BackButton>
          <Button
            className="FormikStepperButton"
            isSecondary={true}
            onClick={() => {
              handleGetBalance();
            }}
          >
            Atualizar
          </Button>
          {/* <Button className='FormikStepperButton' onClick={() => setDisplayRegisterModal(true)}>+ Novo cliente</Button> */}
        </ContentButton>
      </ButtonsRow>
      <Container>
        {isLoading ? (
          <ContainerLoading>
            <CircularProgress />
          </ContainerLoading>
        ) : (
          // eslint-disable-next-line array-callback-return
          pageData.map((user: any) => {
            if (user.type_user !== "10") {
              const subtitle = user.email + " - " + user.cellphone;

              return (
                <>
                  <ListItem
                    key={user.id_user}
                    imgSrc={defaultAvatar}
                    title={user.name}
                    subtitle={subtitle}
                    typeAction="client"
                    object={user}
                    onPress={(user: any) => {
                      handleUpdateDetailsUser(user);
                    }}
                  />
                </>
              );
            }
          })
        )}
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE}
          totalItems={data.length}
          offset={offset}
          setOffset={setOffset}
        />
      </Container>
    </>
  );
};
