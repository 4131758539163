import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;

  min-width: max-content;
  height: 60px;

  background: #FFFFFF;
  border-radius: 16px;

  margin: 8px 0px;

  img {
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 20px;
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;

    width: 136px;
    height: 24px;
    border-radius: 8px;

    border: none;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;

    color: ${({ theme }) => theme.colors.gray_2};
  }
`;

export const TextWrapper = styled.div`
  margin: 0 16px;
  margin-right: auto;
  min-width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #263136;
`;

export const Subtitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.blue_gray_2};
  padding-left: 8px;
`;

export const Address = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #445963;
`;

interface DataContainerProps {
  hasBackground?: boolean;
}

export const DataContainer = styled.div<DataContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;

  position: static;
  min-width: fit-content;
  width: 180px;
  height: 56px;
  background: ${props => props.hasBackground ? '#ECF0F2' : null};
  border-radius: 4px;

  margin-left: 8px;
`;

export const DataValue = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Blue Grey/Blue Grey 700 */

  color: #445963;
`;

export const ButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;

  width: 106px;
  height: 40px;

  border: none;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;

  color: ${({ theme }) => theme.colors.gray_2};
`;
