import styled from "styled-components";
import { TContainerInputProps } from "./types";

export const Container = styled.div<TContainerInputProps>`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #78909d;
  border-radius: 8px;
  background-color: ${(props) =>
    props.color === "white" ? "#FFF" : "#ECF0F2"};
`;

export const ContainerInput = styled.input<TContainerInputProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  background-color: ${(props) =>
    props.color === "white" ? "#FFF" : "#ECF0F2"};
  border-radius: 8px;
  border-style: none;
  font-family: inherit;
  &::placeholder {
    font-size: 12px;
    line-height: 16px;
    color: #78909d;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  padding: 8px;
  cursor: pointer;
`;
