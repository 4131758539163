import { useFormik } from 'formik';

import { useState } from 'react';

import { CircularProgress } from "@material-ui/core";
import { Input, ModalContainer } from "../../../components";
import Button from '../../Button';
import api from '../../../services/api';

import { TForgotPasswordModalProps } from './types';

import * as S from './styles'

export const ForgotPasswordModal = ({ modalDismiss }: TForgotPasswordModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      forgotPasswordEmail: '',
    },
    onSubmit: () => {
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const handleForgotPassword = (async () => {

    setIsLoading(true);

    try {

      const objectSend = {
        email: formik.values.forgotPasswordEmail
      }

      await api.post('api/forgotPassword', objectSend);
      alert('Solitição realizada com sucesso! Verifique sua caixa de email e também a caixa de spam.');
      modalDismiss();

    } catch (error) {
      alert('Algo de errado. Confirme se o email fornecido está correto.')
    };

    setIsLoading(false);
  });

  return (
    <ModalContainer
      modalDismiss={() => modalDismiss()}
      title='Recuperar senha'
      subtitle='Insira seu e-mail abaixo. Enviaremos um e-mail explicando como você pode recuperar sua senha.'
    >
      <S.StyledForm>
        <Input
          name='forgotPasswordEmail'
          type='email'
          placeholder='Insira seu e-mail'
          onChange={formik.handleChange}
          value={formik.values.forgotPasswordEmail}
          color='gray'
        />

        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button isSecondary={false} onClick={() => { handleForgotPassword() }}>
            ENVIAR
          </Button>
        )}
      </S.StyledForm>
    </ModalContainer >
  );
};
