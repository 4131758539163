import * as S from "./styles";

import { faqData } from "./utils";

type TContentProps = {
  text: string;
  img?: any;
};

export const AboutUs = () => {
  const ContentBox = ({ text, img }: TContentProps) => {
    return (
      <S.Box>
        <S.Content>
          <S.Text>{text}</S.Text>
        </S.Content>
        <S.ImgContainer>
          <S.Img src={img} />
        </S.ImgContainer>
      </S.Box>
    );
  };

  return (
    <S.Container>
      <S.Title>QUEM SOMOS NÓS?</S.Title>

      <S.InfoContainer>
        {faqData.map((faq) => (
          <ContentBox key={faq.id} text={faq.content} img={faq.img} />
        ))}
      </S.InfoContainer>
    </S.Container>
  );
};
