import { TButtonProps } from "./types";
import { Box, CircularProgress } from "@material-ui/core";

import * as S from './styles'

export const Button = (props: TButtonProps) => {
  const {
    children,
    onClick,
    isSecondary = false,
    size = "medium",
    fullWidth = false,
    isLoading = false,
    disabled,
    ...rest
  } = props;

  return (
    <S.ButtonContainer
      className="Button"
      onClick={onClick}
      isSecondary={isSecondary}
      size={size}
      disabled={disabled || isLoading}
      {...rest}
      style={fullWidth ? { width: '100%' } : undefined}
    >
      {isLoading 
      ? (<Box style={{width: fullWidth ? '100%' : '100px', display: 'flex', justifyContent: 'center'}}>
          <CircularProgress size={15} />
        </Box>)
      : children}
    </S.ButtonContainer>
  );
};

