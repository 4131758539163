import { Routes, Route } from "react-router-dom-v5-compat";

import { HomePage } from "../pages/Home";
import { Layout } from "../components";
import { ROUTES } from "./routes";

export { ERoutesPath } from "./routesPath";

export const AppRoutes = () => (
  <Routes>
    <Route element={<Layout />}>
      {ROUTES.map((route) => (
        <Route key={route.path} path={route.path} element={<route.element />} />
      ))}

      <Route path="*" element={<HomePage />} />
    </Route>
  </Routes>
);
