import styled from "styled-components";
import { Flex } from "../../../components";

export const ChartCounterContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  gap: 16px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const CounterContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;

  width: 100%;
`;

export const SimulatesListContainer = styled(Flex)`
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: #ffffff;
`;

export const Th = styled.th`
  text-align: left;
  background-color: #f4f2ff;
  padding: 10px 15px;
`;

export const Td = styled.td<{ center?: boolean }>`
  min-width: 120px;
  padding: 10px 15px;
  text-align: ${(props) => (props.center ? "center" : "left")};
`;
