import styled, { css } from "styled-components";
import { Flex } from "../../../components";
import { ECircleStatus } from "./types";

const colorModifiers = (status?: ECircleStatus) => {
  switch (status) {
    case ECircleStatus.DONE:
      return css`
        background-color: #a6b4fe;
        color: #ffffff;
      `;

    default:
      return css`
        background-color: #f4f4fb;
        color: #958cd8;
      `;
  }
};

export const QuestionMenuContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 20px;

  width: 100%;
  gap: 16px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const QuestionNumbersContainer = styled.div`
  background-color: #ffffff;
  height: fit-content;
  border-radius: 8px;

  position: sticky;
  top: 62px;
  z-index: 1;

  @media (max-width: 768px) {
    position: fixed;
    right: 0;
    max-width: 270px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

export const Header = styled(Flex)<{ isClose: boolean }>`
  padding: 16px;
  background-color: #7d92fc;
  ${({ isClose }) => css`
    border-radius: ${isClose ? "8px 0 0 8px" : "8px 8px 0 0"};
  `}
`;

export const CircleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 12px;
  justify-content: center;
`;

export const Circle = styled.div<{ status?: ECircleStatus }>`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f4fa;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;

  ${({ status }) => colorModifiers(status)}

  &:hover {
    background-color: #3966fb;
    color: #ffffff;
  }
`;
