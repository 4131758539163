import styled, { css } from "styled-components";
import { TTypographyProps } from "./types";

export const Container = styled.p<TTypographyProps>`
  ${({ color, align, weight, size, family }) => css`
    font-size: ${size}px;
    font-family: ${family
      ? `"${family}", sans-serif`
      : `"Poppins", sans-serif`};
    color: ${color};
    text-align: ${align};
    font-weight: ${weight};

    @media (max-width: 550px) {
      font-size: ${size ? size * 0.875 : 14}px;
    }
  `}
`;
