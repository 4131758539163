import React from "react";

import { AuthProvider } from "./auth";
import { QuestionProvider } from "./question";
// import { DataProvider } from './data';

type TAppProviderProps = {
  children: React.ReactNode;
};

const AppProvider = ({ children }: TAppProviderProps) => {

  return (
    <>
      <AuthProvider>
        <QuestionProvider>
          {/* <DataProvider> */}
          {children}
          {/* </DataProvider> */}
        </QuestionProvider>
      </AuthProvider>
    </>
  );
};

export default AppProvider;