/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import { getStorage, setStorage } from "../utils/localStorageHandler";
import { setToken, getToken, removeToken } from "../utils/auth";

import api, { END_POINTS } from "../services/api";

interface detailsUser {
  name: string;
  id_user: number;
  email: string;
}
interface AuthContextData {
  isUserLogged: boolean;
  user: detailsUser;
  typeUser: string;
  isFeedbackQuestion: boolean;
  isPaidActive: boolean;
  handleFeedbackQuestion(param: boolean): void;
  signIn(email: any, password: any): void;
  signOut: () => void;
}
type TAuthProviderProps = {
  children: React.ReactNode;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider = ({ children }: TAuthProviderProps) => {
  const [typeUser, setTypeUser] = useState("");
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [user, setUser] = useState<detailsUser>({} as detailsUser);
  const [isFeedbackQuestion, setIsFeedbackQuestion] = useState(false);
  const [isPaidActive, setIsPaidActive] = useState(false);

  useEffect(() => {
    const token = getToken();

    if (token) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      getUserData();
    }
  }, []);

  const handleUserLogin = (value: string) => {
    if (value !== "") {
      const professional = String(getStorage("PROFESSIONAL"));
      setIsUserLogged(true);
    } else {
      setIsUserLogged(false);
      setIsFeedbackQuestion(false);
    }
  };

  const handleSaveUser = (user: detailsUser) => {
    setUser(user);
  };

  const handleTypeUser = (value: string) => {
    setTypeUser(value);
  };

  const handleFeedbackQuestion = (value: boolean) => {
    setIsFeedbackQuestion(value);
  };

  const signIn = async (email: string, password: string) => {
    try {
      const response = await api.post(END_POINTS.login, { email, password });

      const { token } = response.data;
      api.defaults.headers.authorization = `Bearer ${token}`;
      setToken(token);

      getUserData();
    } catch (err) {
      return Promise.reject(
        "Falha na autenticação. Verifique se as credenciais informadas estão corretas."
      );
    }
  };

  const signOut = async () => {
    try {
      await api.post(END_POINTS.logout);

      setIsUserLogged(false);
      handleTypeUser("");
      handleUserLogin("");
      setIsPaidActive(false);

      const hasCompletedTutorial = localStorage.getItem("REVISE_TUTORIAL");
      localStorage.clear();
      localStorage.setItem("REVISE_TUTORIAL", hasCompletedTutorial ?? "");
      removeToken();
    } catch (err) {
      console.error(err);
    }
  };

  const getUserData = async () => {
    try {
      const responseUserAccount = await api.get(END_POINTS.userAccount);

      const userAccount = responseUserAccount.data;

      setStorage("USER_NAME", userAccount.name);
      setStorage("ID_ACCOUNT", userAccount.id);
      setStorage("EMAIL", userAccount.email);
      setIsPaidActive(Boolean(userAccount.paid));

      handleSaveUser({
        name: userAccount.name,
        id_user: userAccount.id,
        email: userAccount.email,
      });

      if (userAccount.type_user === 10) {
        setStorage("PROFESSIONAL", "true");
        handleUserLogin("professional");
        handleTypeUser("10");
        handleFeedbackQuestion(true);
      } else {
        setStorage("CLIENT", "true");
        handleUserLogin("client");
        handleTypeUser("1");
        if (userAccount.paid === 1) {
          handleFeedbackQuestion(true);
        } else {
          handleFeedbackQuestion(false);
        }
      }
    } catch (error) {
      alert("Erro na autenticação. Verifique as credenciais utillizadas.");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isUserLogged,
        user,
        typeUser,
        isFeedbackQuestion,
        isPaidActive,
        signIn,
        signOut,
        handleFeedbackQuestion,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
