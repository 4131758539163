import { useContext, useEffect } from "react";

import { Container } from "./styles";

import { BannerHome } from "./BannerHome";
import { AboutUs } from "./AboutUs";
import { FaqQuestions } from "./FaqQuestions";

import { AuthContext } from "../../contexts/auth";
import { useNavigate } from "react-router-dom-v5-compat";
import { FeedbackPage } from "./FeedbackPage";
import { PlanValues } from "../PlanValues";
import { ERoutesPath } from "../../routes";

export const HomePage = () => {
  const { isUserLogged, typeUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLogged && typeUser === "10") {
      navigate(ERoutesPath.INICIO_PROFISSIONAL);
    }
  }, [typeUser, isUserLogged]);

  return (
    <Container>
      <BannerHome />
      <AboutUs />
      <FeedbackPage />
      <FaqQuestions />

      <PlanValues />
    </Container>
  );
};
