import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 40px;

  background-color: #ffffff;
`;

export const MasonryContainer = styled.div`
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;

  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;

  width: 100%;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    max-width: 500px;
  }
`;

export const Item = styled.div<{ height?: string }>`
  display: inline-block;
  width: -webkit-fill-available;
  height: ${({ height }) => (height ? height : "350px")};
  margin: 10px 0;
`;

export const ImageItem = styled.div<{ img: string; height?: string }>`
  display: inline-block;
  width: -webkit-fill-available;
  height: ${({ height }) => (height ? height : "400px")};

  border-radius: 16px;

  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const FaqItem = styled.div`
  height: 200px;
  width: -webkit-fill-available;
  background-color: #c1c4cb;
  border-radius: 16px;
  margin: 10px 0;

  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;

  display: flex;
  flex-direction: column;
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #000000;
  width: 100px;
  height: 100px;
  border-radius: 50%;

  left: 37%;
  position: relative;
  top: -50px;

  z-index: 5;
`;
