export const initialFormData = {
  course: "",
  matter: "",
  bank: "",
  institution: "",
  year: "",
  difficulty: "",
  weight: "",
  feedback: "",
  comment_correction: "",
  image: null,
  description_issue: null,
  alternatives_image: null,
  alternative_a: "",
  alternative_b: "",
  alternative_c: "",
  alternative_d: "",
  alternative_e: "",
  description: "",
  flash_card: false,
};

export const optionsWeight = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
];

export const optionsDifficulty = [
  {
    value: "Fácil",
    label: "Fácil",
  },
  {
    value: "Médio",
    label: "Médio",
  },
  {
    value: "Difícil",
    label: "Difícil",
  },
];

export const optionsCorrectQuestion = [
  {
    value: "A",
    label: "A",
  },
  {
    value: "B",
    label: "B",
  },
  {
    value: "C",
    label: "C",
  },
  {
    value: "D",
    label: "D",
  },
  {
    value: "E",
    label: "E",
  },
];
