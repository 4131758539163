import * as S from './styles'
import AddCircleIcon from '@mui/icons-material/AddCircle';

type TCardProps = {
  frontContent: string,
  backContent?: string,
  color: string
  textColor: string
}

export const Card = ({ frontContent, backContent, color, textColor }: TCardProps) => {

  return (
    <S.Card>
      <S.FrontFace color={color} textColor={textColor}>
        <S.IconWrapper>
          <AddCircleIcon />
        </S.IconWrapper>
        {frontContent}</S.FrontFace>
      <S.BackFace color={color} textColor={textColor}>{backContent}</S.BackFace>
    </S.Card>

  );
}

