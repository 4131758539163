import { Box } from "@material-ui/core";
import { Step } from "react-joyride";
import tutorial from "../../../assets/imgs/tutorial.png";
import tutorialFim from "../../../assets/imgs/tutorial1.png";
import { Button, Typography } from "../../../components";
import { useIsMobile } from "../../../hooks";

import * as S from "./styles";

export const TUTORIAL_STEPS = (): Array<Step> => {
  const isMobile = useIsMobile();

  return [
    {
      content: (
        <Box display="flex" flexDirection="column" gap={1.5} maxHeight="500px">
          <img
            src={tutorial}
            style={{
              objectFit: "cover",
              height: "100%",
              width: "100%",
              maxHeight: "330px",
              maxWidth: "330px",
            }}
          />

          <Typography align="left">
            Olá! Preparamos um pequeno tutorial para guiar você nas
            funcionalidades da plataforma.
          </Typography>
          <Typography align="left">
            Utilize o menu para explorar os diversos recursos e seções
            disponíveis. Descubra como utilizar de forma mais eficaz.
          </Typography>
        </Box>
      ),
      locale: {
        skip: (
          <Button isSecondary size="small">
            Pular
          </Button>
        ),
        next: "Próximo",
      },
      placement: "center",
      target: "body",
    },
    {
      target: ".tutorial-home",
      content: (
        <Typography align="left">
          Retorne à página inicial por meio deste.
        </Typography>
      ),
      placement: isMobile ? "bottom-start" : "right",
      locale: {
        skip: (
          <Button isSecondary size="small">
            Pular
          </Button>
        ),
        next: "Próximo",
        back: "",
      },
    },
    {
      target: ".tutorial-question",
      content: (
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Typography align="left">
            Aqui você acessa nosso banco de questões com um filtro diferenciado.
          </Typography>
          <Typography align="left">
            Você pode filtrar por disciplina, assunto específico, banca, ano da
            prova, dificuldade da questão e o tempo que você dispõe.
          </Typography>
        </Box>
      ),
      placement: isMobile ? "bottom-start" : "right",
      locale: {
        skip: (
          <Button isSecondary size="small">
            Pular
          </Button>
        ),
        next: "Próximo",
        back: "",
      },
    },
    {
      target: ".tutorial-practice-test",
      content: (
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Typography align="left">Definir texto</Typography>
        </Box>
      ),
      placement: isMobile ? "bottom-start" : "right",
      locale: {
        skip: (
          <Button isSecondary size="small">
            Pular
          </Button>
        ),
        next: "Próximo",
        back: "",
      },
    },
    {
      target: ".tutorial-reviews",
      content: (
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Typography align="left">
            Nesta aba você pode selecionar os assuntos que estudou e deixar que
            o nosso site programe revisões de acordo com os seus erros.
          </Typography>
          <Typography align="left">
            Clique em “assuntos estudados”, marque as disciplinas ou assunto
            específico que estudou e as revisões aparecerão no seu cronograma.
          </Typography>
        </Box>
      ),
      placement: isMobile ? "bottom-start" : "right",
      locale: {
        skip: (
          <Button isSecondary size="small">
            Pular
          </Button>
        ),
        next: "Próximo",
        back: "",
      },
    },
    {
      target: ".tutorial-fast-card",
      content: (
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Typography align="left">
            Aqui você estuda respondendo alternativas direto das provas
            anteriores no formato de “verdadeiro ou falso”.
          </Typography>
          <Typography align="left">
            Você também pode filtrar como desejar e usar para ter um estudo
            direcionado e específico!
          </Typography>
        </Box>
      ),
      placement: isMobile ? "bottom-start" : "right",
      locale: {
        skip: (
          <Button isSecondary size="small">
            Pular
          </Button>
        ),
        next: "Próximo",
        back: "",
      },
    },
    {
      target: ".tutorial-redo",
      content: (
        <Box display="flex" flexDirection="column" gap={1.5} maxHeight="500px">
          <Typography align="left">
            Aqui, se necessário, você pode revisar este tutorial a qualquer
            momento.
          </Typography>
          <Typography align="left">
            Qualquer dúvida, estamos disponíveis por qualquer canal de
            comunicação que desejar (whatsapp, instagram ou email).
          </Typography>
          <S.ImgHideMobile src={tutorialFim} />
        </Box>
      ),
      placement: isMobile ? "bottom-start" : "right",
      locale: {
        skip: (
          <Button isSecondary size="small">
            Pular
          </Button>
        ),
        next: "Próximo",
        back: "",
        last: "Fim!",
      },
    },
  ];
};
