import { Fragment } from "react/jsx-runtime";
import * as S from "./styles";
import { TBreadcrumbsProps } from "./types";

import { useNavigate } from "react-router-dom-v5-compat";

export const Breadcrumbs = ({ items }: TBreadcrumbsProps) => {
  const navigate = useNavigate();

  const handleClick = (route: string | undefined, onClick?: () => void) => {
    if (onClick) {
      onClick();
    }

    if (route) {
      navigate(route);
    }
  };

  return (
    <S.BreadcrumbsContainer>
      {items.map((item, index) => (
        <Fragment key={index}>
          {item.route ? (
            <S.BreadcrumbLink
              to={item.route}
              onClick={(e) => {
                e.preventDefault();
                handleClick(item.route, item.onClick);
              }}
            >
              {item.label}
            </S.BreadcrumbLink>
          ) : (
            <S.BreadcrumbText>{item.label}</S.BreadcrumbText>
          )}
          {index < items.length - 1 && (
            <S.BreadcrumbSeparator>/</S.BreadcrumbSeparator>
          )}
        </Fragment>
      ))}
    </S.BreadcrumbsContainer>
  );
};
