import { useContext, useState } from "react";

import Button from "../../../components/Button";
import { Link } from "react-scroll";
import { LoginModal, RegisterModal } from "../../../components";

import { logoRevise } from "../../../assets";

import { AuthContext } from "../../../contexts/auth";

import * as S from "./styles";

export const BannerHome = () => {
  const [displayRegisterModal, setDisplayRegisterModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const { isUserLogged, isPaidActive } = useContext(AuthContext);

  return (
    <S.Background>
      <S.BannerHomeContainer>
        <S.Content>
          <div>
            <S.TextContainer>
              <S.Title>
                Estude para a sua prova do CBR com mais de 1600 questões na
                palma da sua mão!
              </S.Title>
            </S.TextContainer>

            <S.ButtonContainer>
              {!isUserLogged && (
                <>
                  <Button
                    isSecondary
                    onClick={() => setDisplayRegisterModal(true)}
                    fullWidth
                    color="white"
                  >
                    CADASTRE-SE AGORA
                  </Button>

                  <Button
                    isSecondary
                    onClick={() => setLoginModal(true)}
                    fullWidth
                    color="white"
                  >
                    ENTRAR
                  </Button>
                </>
              )}

              {isUserLogged && !isPaidActive && (
                <>
                  <Link
                    activeClass="active"
                    to="planos"
                    spy={true}
                    smooth={true}
                    offset={-120}
                    duration={500}
                  >
                    <Button isSecondary color="white">
                      Conheça nossos planos
                    </Button>
                  </Link>
                </>
              )}
            </S.ButtonContainer>
          </div>

          <S.LogoContainer
            src={logoRevise}
            alt="Revise Radio"
            width={400}
            height={400}
          />
        </S.Content>

        {displayRegisterModal && (
          <RegisterModal
            modalDismiss={() => setDisplayRegisterModal(false)}
            handleRegisterUser={() => {}}
          />
        )}

        {loginModal && (
          <LoginModal
            modalDismiss={() => setLoginModal(false)}
            typeAccess="client"
          />
        )}
      </S.BannerHomeContainer>
    </S.Background>
  );
};
