import { Mail } from "@material-ui/icons";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";

import { Link, Typography } from "../../../components";

import * as S from "./styles";

export const Footer = () => (
  <>
    <S.Container>
      <S.Content>
        <S.HelperContainer>
          <Typography
            family="Montserrat"
            size={38}
            weight={900}
            color="#FFFFFF"
            align="center"
            style={{ maxWidth: "250px" }}
          >
            ALGUMA DÚVIDA?
          </Typography>

          <Typography color="#FFFFFF">Entre em contato conosco!</Typography>
        </S.HelperContainer>

        <S.ContainerInformation>
          <S.ContainerInline>
            <Mail style={{ color: "#eceff1" }} />
            <Link color="#FFFFFF" href="mailto:plataformarevise@gmail.com">
              plataformarevise@gmail.com
            </Link>
          </S.ContainerInline>

          <S.ContainerInline>
            <LocalPhoneIcon style={{ color: "#eceff1" }} />
            <Typography color="#FFFFFF">(81) 9 9952-6246</Typography>
          </S.ContainerInline>

          <S.ContainerInline>
            <WhatsAppIcon style={{ color: "#eceff1" }} />
            <Typography color="#FFFFFF">WhatsApp:</Typography>
            <Link
              color="#FFFFFF"
              href="https://wa.me/5581999526246?text=Ol%C3%A1%2C+estou+entrando+em+contato+pois+tenho+interesse+na+plataforma+para+evoluir+nos+meus+estudos.+Pode+me+ajudar%3F"
              target="_blank"
            >
              Clique aqui
            </Link>
          </S.ContainerInline>

          <S.ContainerInline>
            <InstagramIcon style={{ color: "#eceff1" }} />
            <Link
              color="#FFFFFF"
              href="https://instagram.com/revise.radio"
              target="_blank"
            >
              @revise.radio
            </Link>
          </S.ContainerInline>
        </S.ContainerInformation>
      </S.Content>

      <Typography
        family="Montserrat"
        size={12}
        weight={300}
        color="#FFFFFF"
        align="center"
      >
        Copyright © Revise - Todos os direitos reservados
      </Typography>
    </S.Container>
  </>
);
