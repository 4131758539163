const light = {
  title: 'light',

  colors: {
    primary: '#547CFF',
    primary_dark: '#3A66FB',

    gray_1: '#1A1A1A',
    gray_2: '#545454',
    gray_3: '#9C9B9B',
    gray_4: '#C7C7C7',
    gray_5: '#FAF8F9',
    gray_6: '#F1F1F1',

    gray_background: '#ECEFF1',

    yellow_1: '#FAC639',
    yellow_2: '#F4D939',
    yellow_3: '#F9EC9C',
    yellow_4: '#FEFBEB',
    yellow_5: '#A37701',

    blue_1: '#001334',
    blue_2: '#173AAD',
    blue_3: '#3A66FB',
    blue_4: '#547CFF',
    blue_5: '#A9BDFF',

    blue_gray_1 : '#445963',
    blue_gray_2 : '#607D8B',
    blue_gray_3 : '#ECF0F2',
    blue_gray_4 : '#78909D',

    auxiliary_beige: '#FFCE76',
    auxiliary_blue: '#1B3C68',
    auxiliary_green: '#00B8C0',
    auxiliary_green_50: 'rgba(0, 184, 192, 0.5)',

    auxiliary_red: '#FF304F',

    auxiliary_black: '#000000',
    auxiliary_white: '#FFFFFF',
  },
};

export default light;
