import styled, { css } from "styled-components";

interface ButtonContainerProps {
  isSecondary: boolean;
  size: "small" | "medium" | "large";
}

const buttonContainerSizeVariations = {
  small: css`
    height: 32px;
    padding: 4px 8px;
    //styleName: Paragraphs/Body Small;
    font-size: 14px;
    font-weight: 400;
  `,
  medium: css`
    height: 48px;
    padding: 8px 16px;
    //styleName: Extras/Button/Button;
    font-size: 16px;
    font-weight: 400;
  `,
  large: css`
    height: 56px;
    padding: 16px 24px;
    //styleName: Extras/Button/Button - SemiBold;
    font-size: 16px;
    font-weight: 600;
  `,
};

const ButtonPrimaryStyle = css`
  background: #3a66fb;
  color: #fff;
  &[disabled] {
    background: #c1cffe;
  }
  &:active {
    background: #3a66fb;
  }
`;

const colorModifiers = (mode?: string) => {
  switch (mode) {
    case "white":
      return css`
        background: white;
        color: #3a66fb;
      `;

    case "whiteBlack":
      return css`
        background: white;
        color: #000000;
      `;

    default:
      return css`
        background: none;
        color: #3a66fb;
        &[disabled] {
          color: #c1cffe;
        }
        &:active {
          color: #3a66fb;
        }
        border: 1px solid #3a66fb;
      `;
  }
};

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: fit-content;
  border-radius: 8px;
  border: 4px solid transparent;

  white-space: nowrap;
  font-family: Poppins;
  font-style: normal;
  text-align: center;
  line-height: 24px;
  letter-spacing: 0px;

  ${({ size }) => buttonContainerSizeVariations[size]}

  ${({ isSecondary, color }) =>
    isSecondary ? colorModifiers(color) : ButtonPrimaryStyle};
`;
