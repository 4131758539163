import styled from "styled-components";

export const Container = styled.div`
  padding: 30px;
  background: #ffffff;
`;

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 65px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const Title = styled.h2`
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  margin: 40px 0 45px 0;

  @media (max-width: 800px) {
    font-size: 20px;
  }
`;

export const Text = styled.div`
  font-family: Montserrat, sans-serif;
  text-align: justify;
  font-size: 24px;
  font-weight: 600;
`;

export const Box = styled.div`
  border: 2px solid;
  position: relative;
  width: 100%;
`;

export const Content = styled.div`
  padding: 50px 20px 20px;
`;

export const ImgContainer = styled.div`
  position: absolute;
  top: -44px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  padding: 0 10px;
`;

export const Img = styled.img`
  max-width: 85px;
  max-height: 85px;
  object-fit: cover;
`;
