import { TSimulate } from "../types";

export enum ECircleStatus {
  NONE = "NONE",
  DONE = "DONE",
}

export type TSelectedQuestionData = {
  [index: number]: {
    questionId: number;
    alternative: string;
  };
};

export type TQuestionMenuProps = {
  onSelectQuestion: (questionIndex: number) => void;
  selectedQuestions: TSelectedQuestionData;
};

export type TChangeStatusResponse = {
  data: TSimulate;
};
