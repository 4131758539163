import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { TSelectProps } from './types';
import * as S from './styles'

export const Select = ({ options, name, placeholder, ...rest }: TSelectProps) => (
  <S.Container>
    <S.ContainerSelect name={name} {...rest}>
      {placeholder && (
        <S.Option value="" disabled >
          {placeholder}
        </S.Option>
      )}

      {options.map(({ value, label }) => (
        <S.Option value={value} key={value}>
          {label}
        </S.Option>
      ))}
    </S.ContainerSelect>

    <S.IconWrapper><ArrowDropDownIcon color="action" /></S.IconWrapper>
  </S.Container>
);
