import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 184px;
  height: 184px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid #A9BDFF;

  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 8px rgba(26, 26, 26, 0.2);
  }

`;

export const Title = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: capitalize;

  color: ${({theme}) => theme.colors.primary_dark};
`;

export const Subtitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
  text-align: center;

  color: ${({theme}) => theme.colors.gray_1};
`;

