export const PLAN_VALUES = [
  {
    title: "PLANO MENSAL",
    itens: [
      "Plataforma de questões resolvidas;",
      "Revise Rápido (verdadeiro ou falso);",
      "Benefício do usuário durante período contratado (novas funcionalidades da plataforma, novos materiais/questões e outros).",
    ],
    link: "https://www.mercadopago.com.br/subscriptions/checkout?preapproval_plan_id=2c938084910f95b601912531bda9063a",
    price: "R$ 299,90",
  },
  {
    title: "PLANO QUADRIMESTRAL",
    itens: [
      "Plataforma de questões resolvidas;",
      "Revise Rápido (verdadeiro ou falso);",
      "Benefício do usuário durante período contratado (novas funcionalidades da plataforma, novos materiais/questões e outros).",
    ],
    link: "https://www.mercadopago.com.br/subscriptions/checkout?preapproval_plan_id=2c938084910f95b901912535d7dd0657",
    price: "R$ 699,90",
  },
  {
    title: "PLANO SEMESTRAL",
    itens: [
      "Plataforma de questões resolvidas;",
      "Revise Rápido (verdadeiro ou falso);",
      "Benefício do usuário durante período contratado (novas funcionalidades da plataforma, novos materiais/questões e outros).",
    ],
    link: "https://mpago.la/161Ftnr",
    price: "R$ 899,90",
  },
];
