import axios from "axios";

export { END_POINTS } from "./routesPath";

const api = axios.create({
  baseURL: "https://digitalocean.plataformarevise.com",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default api;
