import { PLAN_VALUES } from "./helper";
import * as S from "./styles";
import lightning from "../../assets/icon/lightning.svg";

export const PlanValuesBlackNovember = ({ isModal = false }) => {
  const openLink = (link: string) => {
    window.open(link);
  };

  return (
    <div id="plan">
      <S.Container id="planos" isModal={isModal}>
        {!isModal && <S.Title isBigger>Planos</S.Title>}

        <S.BackNovemberContainer
          style={{ width: `${isModal ? "100%" : "100vw"}` }}
        >
          <S.BlackTitle isModal={isModal}>
            <img src={lightning} width="40px" height="40px" />
            <S.Text size={40} color="#3A66FB">
              Promoção
            </S.Text>
            <S.Text
              size={36}
              color="#000000"
              style={{
                padding: "5px",
                backgroundColor: "#3A66FB",
                borderRadius: "8px",
                textAlign: "center",
              }}
            >
              Black November
            </S.Text>
          </S.BlackTitle>

          <S.Text size={20} color="#FFFFFF" style={{ textAlign: "center" }}>
            Adquira qualquer plano e{" "}
            <span style={{ textDecoration: "underline" }}>
              ganhe o dobro do acesso!
            </span>
          </S.Text>
        </S.BackNovemberContainer>

        <S.CardsContainer>
          {PLAN_VALUES.map((plan) => (
            <S.PlanContainer key={plan.title}>
              <S.PlanTitle>
                <S.Title style={{ color: "#FFFFFF" }}>{plan.title}</S.Title>
                <S.PlanBlackContent>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#FFFFFF",
                      fontFamily: '"Montserrat", sans-serif',
                      padding: "16px",
                      textAlign: "center",
                    }}
                  >
                    Se inscrevendo{" "}
                    <span style={{ color: "#3A66FB", fontWeight: "600" }}>
                      em novembro
                    </span>{" "}
                    você ganha o dobro de acesso
                  </p>
                </S.PlanBlackContent>
              </S.PlanTitle>

              {plan.itens.map((item) => (
                <li key={item} style={{ padding: "0 20px" }}>
                  {item}
                </li>
              ))}

              <S.Title isBigger>{plan.price}</S.Title>

              <S.ButtonContainer
                color="whiteBlack"
                onClick={() => openLink(plan.link)}
              >
                ASSINE AGORA
              </S.ButtonContainer>
            </S.PlanContainer>
          ))}
        </S.CardsContainer>
      </S.Container>
    </div>
  );
};
