import { useNavigate } from "react-router-dom-v5-compat";
import { ERoutesPath } from "../../../routes";
import { Button, Flex, Typography, Tag } from "../../../components";
import { CircularProgress } from "@material-ui/core";

import { useContext, useEffect, useState } from "react";
import api, { END_POINTS } from "../../../services/api";
import { AuthContext } from "../../../contexts/auth";
import { formatDateDayHours } from "../../../utils/date";
import { deleteIcon, playIcon, trophyIcon } from "../../../assets";

import { ESimulateStatus, TSimulate } from "../types";

import * as S from "./styles";

export const SimulatesList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [simulates, setSimulates] = useState<Array<TSimulate>>([]);

  const idAccount = user.id_user;

  const handleNewTest = () => {
    navigate(ERoutesPath.SIMULADOS_NOVO);
  };

  const handleStartContinue = (simulateId: number) => {
    navigate(
      ERoutesPath.SIMULADO_RESPONDER.replace(":id", simulateId.toString())
    );
  };

  const getSimulates = async () => {
    try {
      setIsLoading(true);
      const simulateData = await api.get<Array<TSimulate>>(
        `${END_POINTS.simulates}/${idAccount}`
      );

      setSimulates(simulateData.data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (simulateId: number) => {
    await api.delete(`${END_POINTS.simulates}/${simulateId}`);

    getSimulates();
  };

  useEffect(() => {
    getSimulates();
  }, []);

  return (
    <>
      <Typography color="#6E6893" family="Montserrat" size={20}>
        Meus simulados
      </Typography>

      <S.SimulatesListContainer direction="column" width="100%" gap={8}>
        {isLoading ? (
          <Flex justifyContent="center">
            <CircularProgress />
          </Flex>
        ) : (
          <>
            <Flex justifyContent="space-between">
              <div></div>

              <Button size="small" onClick={handleNewTest}>
                Novo Simulado
              </Button>
            </Flex>

            <S.TableContainer>
              <S.Table>
                <thead style={{ borderBottom: "1px solid #D9D5EC" }}>
                  <tr>
                    <S.Th>
                      <Typography color="#6e6893" size={12} weight={600}>
                        Simulado
                      </Typography>
                    </S.Th>
                    <S.Th>
                      <Typography color="#6e6893" size={12} weight={600}>
                        Criado em
                      </Typography>
                    </S.Th>
                    <S.Th>
                      <Typography color="#6e6893" size={12} weight={600}>
                        Última Atualização
                      </Typography>
                    </S.Th>
                    <S.Th>
                      <Typography color="#6e6893" size={12} weight={600}>
                        Andamento
                      </Typography>
                    </S.Th>
                    <S.Th>
                      <Typography color="#6e6893" size={12} weight={600}>
                        Ações
                      </Typography>
                    </S.Th>
                  </tr>
                </thead>
                <tbody>
                  {simulates.map((simulate) => (
                    <tr
                      key={simulate.id}
                      style={{ borderBottom: "1px solid #D9D5EC" }}
                    >
                      <S.Td>
                        <Typography size={14}>{simulate.name}</Typography>
                      </S.Td>
                      <S.Td>
                        <Typography size={14}>
                          {formatDateDayHours(simulate.created_at)}
                        </Typography>
                      </S.Td>
                      <S.Td>
                        <Typography size={14}>
                          {formatDateDayHours(simulate.updated_at)}
                        </Typography>
                      </S.Td>
                      <S.Td>
                        {simulate.status === ESimulateStatus.FINISHED && (
                          <Tag type="SUCCESS" text="Concluído" />
                        )}
                        {simulate.status === ESimulateStatus.CREATED && (
                          <Tag type="ERROR" text="Não iniciado" />
                        )}
                        {simulate.status === ESimulateStatus.STARTED && (
                          <Tag type="WARNING" text="Iniciado" />
                        )}
                        {simulate.status === ESimulateStatus.PAUSED && (
                          <Tag type="INFO" text="Pausado" />
                        )}
                      </S.Td>
                      <S.Td center>
                        <Flex gap={16}>
                          {simulate.status === ESimulateStatus.FINISHED ? (
                            <img
                              src={trophyIcon}
                              height={20}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <>
                              <img
                                src={playIcon}
                                height={20}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleStartContinue(simulate.id)}
                              />
                              <img
                                src={deleteIcon}
                                height={20}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDelete(simulate.id)}
                              />
                            </>
                          )}
                        </Flex>
                      </S.Td>
                    </tr>
                  ))}
                </tbody>
              </S.Table>
            </S.TableContainer>
          </>
        )}
      </S.SimulatesListContainer>
    </>
  );
};
