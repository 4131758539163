import styled from "styled-components";
import { TAlternativeProps } from "./types";
import { Flex } from "../../../components";

const getBackgroundColor = (props: TAlternativeProps) => {
  if (props.isCorrect === true) {
    return "#7DC579";
  }
  if (props.isSelected && props.isCorrect === false) {
    return "#FF8181";
  }
  if (props.isSelected) {
    return "#547cff";
  }
  return "unset";
};

export const Container = styled(Flex)`
  display: flex;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;

  @media (max-width: 800px) {
    padding: 12px;
    gap: 8px;
  }
`;

export const AlternativeWrapper = styled(Flex)<{ isDisabled: boolean }>`
  &:hover {
    > div:first-child {
      background-color: #f1f1fa;
      cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
    }
    img {
      opacity: 1;
    }
  }
`;

export const AlternativeAction = styled(Flex)`
  border-radius: 50%;
  position: relative;
  background-color: transparent;

  @media (max-width: 800px) {
    width: 20px;
    height: 20px;
    background-color: #f1f1fa;
  }

  img {
    width: 12px;
    height: 12px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    @media (max-width: 800px) {
      opacity: 0.3;
      width: 8px;
      height: 8px;
    }
  }
`;

export const Alternative = styled(Flex)<TAlternativeProps>`
  border-radius: 8px;
  padding: 10px;

  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};

  background-color: ${(props) => getBackgroundColor(props)};

  @media (max-width: 800px) {
    padding: 5px;
  }
`;

export const Letter = styled(Flex)<{ letter: string }>`
  border-radius: 50%;
  border: 1px solid #3a66fb;
  background-color: #ffffff;
  font-size: 14px;
  color: #3a66fb;

  ::before {
    content: "${(props) => props.letter}";
  }

  @media (max-width: 800px) {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
`;

export const Label = styled.p<{ isStrike: boolean }>`
  text-decoration: ${(props) => (props.isStrike ? "line-through" : "none")};
  font-size: 14px;

  @media (max-width: 800px) {
    font-size: 10px;
  }
`;

// helper
export const Image = styled.img`
  max-width: 700px;
  width: 100%;
`;

export const FeedbackContainer = styled(Flex)<{ isCorrect: boolean }>`
  padding: 16px;
  border: 1.5px solid ${({ isCorrect }) => (isCorrect ? "#7DC579" : "#FF8181")};
  border-radius: 8px;

  @media (max-width: 800px) {
    gap: 10px;
    padding: 12px;
  }
`;

export const Icon = styled.img<{ rotate: boolean }>`
  transition: transform 0.3s ease-in-out;

  ${({ rotate }) =>
    rotate &&
    `
    transform: rotate(180deg);
  `}
`;

export const ReportButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  padding: 4px;
  border: unset;
  border-radius: 8px;
  margin-left: auto;
`;

export const ReportImg = styled(Flex)`
  padding: 5px;
  background-color: #ffcecc;
  border-radius: 8px;
`;
