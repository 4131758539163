import React, { useContext, useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select, { SelectChangeEvent } from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

import Button from "../../components/Button";

import * as S from "./styles";

//components
import api from "../../services/api";
import { FormControl } from "@material-ui/core";
import { questionStudied } from "./interface";
import { AuthContext } from "../../contexts/auth";
import { useLocation } from "react-router-dom-v5-compat";
import { revisionsProps } from "../Revisions/interface";

import { TQuestion } from "../../contexts/question";

import { Flex, PageTemplate, Question } from "../../components";
import { ERoutesPath } from "../../routes";

export const RevisionGeneralPage: React.FC = () => {
  const { user } = useContext(AuthContext);

  const location = useLocation();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [selectedArea, setSelectedArea] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState<string[]>([]);
  const [themes, setThemes] = useState<string[]>([]);

  const [week, setWeek] = useState("");

  const idAccount = user.id_user;
  const time = [
    "30 min",
    "1h",
    "1h30",
    "2h",
    "2h30",
    "3h",
    "3h30",
    "4h",
    "4h30",
    "5h",
  ];

  useEffect(() => {
    // NOTE: VERIFY
    const objectRevision = location.state as revisionsProps;
    const getWeek = objectRevision.area.split(" ");
    if (getWeek) {
      setWeek(getWeek[2]);
    }

    handleGetSubjectsStudied(objectRevision);
  }, [location]);

  const handleGetSubjectsStudied = async (objectRevision: any) => {
    try {
      setIsLoading(true);
      let themesList: string[] = [];
      await api.get(`/api/student/revision/${objectRevision.id}`);
      const questionsSubject = await api.get(
        `/api/student/subject/studied/${idAccount}`
      );

      setAllData(questionsSubject.data);

      questionsSubject.data.forEach((questionParam: questionStudied) => {
        if (
          themesList.indexOf(questionParam.course) === -1 &&
          questionParam.course !== "-"
        ) {
          themesList.push(questionParam.course);
        }
      });
      setThemes(themesList);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedArea.length > 0) {
      let filterQuestions: questionStudied[] = [];

      allData.filter((question: questionStudied) => {
        selectedArea.forEach((value) => {
          if (
            question.course.toLowerCase().indexOf(value.toLowerCase()) !== -1 &&
            question.course !== "-"
          ) {
            filterQuestions.push(question);
          }
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea]);

  const handleChange = (event: SelectChangeEvent<string[]>, param: string) => {
    switch (param) {
      case "area":
        setSelectedArea(event.target.value as string[]);
        break;
      case "time":
        setSelectedTime(event.target.value as string[]);
        break;
    }
  };

  const handleGenerateQuestions = async () => {
    setPageData([]);

    if (selectedArea.length === 0 || selectedTime.length === 0) {
      alert("Desculpe. Todos os filtros precisam estar preenchidos.");
      return;
    }

    const objectFilter = {
      areas: selectedArea,
      time: selectedTime,
      idAccount: idAccount,
      week: week,
    };

    try {
      const getQuestions = await api.post(
        `/api/student/revision/week`,
        objectFilter
      );

      setPageData(getQuestions.data);
    } catch (err) {}
  };

  const revisionGeneralBreadcrumb = [
    { label: "Página inicial", route: ERoutesPath.ROOT },
    {
      label: "Cronograma de revisões",
      route: ERoutesPath.REVISOES,
    },
    { label: "Revisão Geral" },
  ];

  return (
    <PageTemplate
      title="Revisão Geral"
      breadcrumbsItems={revisionGeneralBreadcrumb}
    >
      <Flex direction="column" width="100%" gap={16}>
        {isLoading ? (
          <Flex width="100%" justifyContent="center">
            <CircularProgress />
          </Flex>
        ) : (
          <>
            <div className="question">
              <h3>Selecionar questões</h3>
            </div>

            <S.ContentFilter>
              <FormControl className="form">
                <InputLabel id="demo-mutiple-checkbox-label">
                  Área(s)
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  value={selectedArea}
                  multiple
                  onChange={(event) => {
                    handleChange(event, "area");
                  }}
                  input={<Input />}
                  renderValue={(selected) => (selected as string[]).join(", ")}
                  MenuProps={MenuProps}
                >
                  {themes.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={selectedArea.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className="form">
                <InputLabel id="demo-mutiple-checkbox-label">Tempo</InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  value={selectedTime}
                  onChange={(event) => {
                    handleChange(event, "time");
                  }}
                  input={<Input />}
                  renderValue={(selected) => selected as string[]}
                  MenuProps={MenuProps}
                >
                  {time.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={selectedTime.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="filterList">
                <Button
                  isSecondary
                  size="medium"
                  onClick={handleGenerateQuestions}
                >
                  Buscar questões
                </Button>
              </div>
            </S.ContentFilter>
          </>
        )}

        {pageData.map((question: TQuestion, key) => (
          <Question data={question} key={question.id} number={key + 1} />
        ))}
      </Flex>
    </PageTemplate>
  );
};
